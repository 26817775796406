/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useCallback, useRef, useState } from 'react';
import { createPopper, type VirtualElement } from '@popperjs/core';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { DialogTitle } from '@/components/Dialog/DialogTitle';
import { DialogContent } from '@/components/Dialog/DialogContent';
import { IconButton } from '../../IconButton/IconButton';
import { Paper } from '../../Paper';
import type { ReduxState } from '../../../types';
import CloseIcon from '../../../../public/images/close.svg';
import iconStyles from '../../../theme/iconStyles.module.scss';
import styles from './styles.module.scss';

type Modifiers = {
  flip?: {
    enabled: boolean;
  };
  preventOverflow?: {
    enabled: boolean;
    boundariesElement: string;
  };
  arrow?: {
    enabled: boolean;
    element?: React.ReactNode;
  };
};

type AnchorEl = Function | HTMLElement | React.RefObject<HTMLElement>;

export type PopperProps = {
  id?: string;
  classes?: {
    paper?: string;
    popper?: string;
    root?: string;
    arrow?: string;
  };
  isOpen: boolean;
  anchorEl: AnchorEl;
  modifiers?: Modifiers;
  strategy?: 'absolute' | 'fixed';
  placement?:
    | 'auto-end'
    | 'auto-start'
    | 'auto'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  children: React.ReactNode;
  disablePortal?: boolean;
  isCorePricePadding?: boolean;
  elevation?: number;
  role?: string;
  ariaLabel?: string;
  ariaLabelledByID?: string;
  popperAriaLabelledByID?: string;
  ariaDescribedByID?: string;
  popperRole?: string;
  popperAriaModal?: boolean;
  popperId?: string;
  hideForBots?: boolean;
};

export type PopperHeaderProps = {
  classes?: {
    heading?: string;
    title?: string;
  };
  children: React.ReactNode;
  handleClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  focusToCloseButton?: boolean;
  ariaLabel?: string;
  ariaLabelledByID?: string;
  hideCloseIcon?: boolean;
  isBorder?: boolean;
  typographyComponent?: React.ElementType;
};

export type PopperBodyProps = {
  children: React.ReactNode;
  ariaDescribedByID?: string;
  classes?: {
    body?: string;
  };
};

export const PopperHeader = ({
  classes = {},
  children,
  handleClose,
  focusToCloseButton,
  ariaLabel,
  ariaLabelledByID,
  hideCloseIcon,
  isBorder = false,
  typographyComponent = 'h2',
}: PopperHeaderProps) => {
  const closeRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    focusToCloseButton && closeRef.current?.focus();
  }, [focusToCloseButton]);

  return (
    <div
      className={cx(isBorder ? styles.popperTitle : styles.popperTitleWithoutBorder, classes.title)}
    >
      <DialogTitle
        className={cx(styles.popperTitleText, classes.heading)}
        id={ariaLabelledByID}
        ariaLabel={ariaLabel}
        typographyComponent={typographyComponent}
      >
        {children || `\u00A0`}
      </DialogTitle>
      {!hideCloseIcon ? (
        <IconButton
          aria-label="Close"
          ref={closeRef}
          className={styles.popperTitleCloseButton}
          onClick={handleClose}
          data-testid="popper-title-close-button"
        >
          <CloseIcon className={cx(iconStyles.defaultIconStyle, iconStyles.fontSizeSmall)} />
        </IconButton>
      ) : null}
    </div>
  );
};

export const PopperBody = (props: PopperBodyProps) => (
  <DialogContent
    className={cx(styles.popperContent, props.classes?.body)}
    ariaDescribedByID={props.ariaDescribedByID}
  >
    {props.children}
  </DialogContent>
);

function getAnchorEl(anchorEl: AnchorEl): Element | VirtualElement {
  return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
}

export function Popper({
  isOpen,
  anchorEl,
  placement: initialPlacement = 'bottom',
  children,
  classes = {},
  modifiers = {},
  strategy = 'absolute',
  disablePortal = false,
  elevation,
  isCorePricePadding,
  role = 'alertdialog',
  ariaLabelledByID,
  ariaDescribedByID,
  popperRole = 'tooltip',
  popperAriaModal = false,
  popperAriaLabelledByID,
  popperId,
  hideForBots = false,
}: PopperProps) {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const id = isOpen ? 'simple-popper' : undefined;
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const isABot = useSelector(({ appData }: ReduxState) => appData.deviceType === 'bot');

  const { root = '' } = classes;
  const {
    flip = {
      enabled: true,
    },
    arrow = {
      enabled: true,
    },
  } = modifiers;
  const handleOpen = useCallback(() => {
    if (!tooltipRef.current || !anchorEl || !isOpen) {
      return;
    }

    const popperModifiers = [
      {
        name: 'flip',
        enabled: flip.enabled,
        options: {
          altBoundary: disablePortal,
          ...(isCorePricePadding && { padding: 100 }),
        },
      },
      {
        name: 'arrow',
        enabled: arrow.enabled,
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          boundariesElement: 'scrollParent',
          altBoundary: disablePortal,
        },
      },
    ];
    createPopper(getAnchorEl(anchorEl), tooltipRef.current, {
      placement: initialPlacement,
      modifiers: popperModifiers,
      strategy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl, isOpen, arrowRef, initialPlacement]);

  const handleRef = useCallback(
    (node: HTMLDivElement) => {
      tooltipRef.current = node;
      handleOpen();
    },
    [tooltipRef, handleOpen]
  );

  if (!isOpen || (isABot && hideForBots)) {
    return null;
  }

  const popper = (
    <div
      id={popperId ?? id}
      role={popperRole}
      aria-modal={popperAriaModal}
      ref={handleRef}
      aria-labelledby={popperAriaLabelledByID}
      style={{
        // Prevents scroll issue, waiting for Popper.js to add this style once initiated.
        position: 'fixed',
        // Fix Popper.js display issue
        top: 0,
        left: 0,
        display: !isOpen ? 'none' : undefined,
      }}
      className={cx(styles.popper, {
        [classes.popper || '']: classes.popper,
        [root || '']: classes.root,
      })}
    >
      {arrow.enabled && <span className={cx(styles.arrow, classes.arrow)} ref={setArrowRef} />}
      <Paper
        id="popperContent"
        aria-modal={!popperAriaModal}
        aria-labelledby={ariaLabelledByID}
        aria-describedby={ariaDescribedByID}
        className={cx(styles.paper, {
          [classes.paper || '']: classes.paper,
        })}
        role={role}
        elevation={elevation}
      >
        {children}
      </Paper>
    </div>
  );

  if (disablePortal) {
    return popper;
  }

  return ReactDOM.createPortal(popper, document.body);
}
